<template>
  <div class="preloader">
    <div class="loader d-flex justify-center align-center">
      <img src="../../assets/images/enumlogos.svg" alt="" width="30" height="34">
    </div>
    <br><br>
    <div class="text-center">
      <slot name="loader-message"></slot>
    </div>

  </div>
</template>

<script>
export default {
name: "LogoLoader"
}
</script>

<style scoped>
.preloader {
  align-items: center;
  backdrop-filter: opacity(0.8);
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.8s linear;
  width: 100%;
  z-index: 9999;
  flex-direction: column;

}

.loader {
  animation: beat .25s infinite alternate;
  transform-origin: center;
}
@keyframes beat{
  to { transform: scale(1.4); }
}


</style>
