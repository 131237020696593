<template>
  <div>
    <LogoLoader/>
  </div>
</template>

<script>
import LogoLoader from "@/components/reusables/LogoLoader";
import {verifyEmail} from "@/services/api/AuthApiService";

export default {
  name: "VerifyEmail",
  components: {LogoLoader},
  created() {
    const { user_id, timestamp, signature } = this.$route.query;
    let data = {
      user_id,
      timestamp,
      signature,
    };
    verifyEmail(data)
        .then((response) => {
          console.log(response)
          this.$router.push("/login");
        })
        .catch((err) => {
          this.$displaySnackbar({
            message: `${err.response.data.detail}`,
            success: false
          })
          console.log(err)

        });
  },

}
</script>

<style scoped>

</style>
